import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * Angular form validator that requires a gender to be selected when a last name is provided.
 * This validator is specifically designed for B2B forms where gender becomes mandatory
 * only when personal information is entered.
 *
 * The validator looks for two specific form controls:
 * - 'gender': The control that becomes required
 * - 'lastName': The control that triggers the requirement
 *
 * @param control - The form group containing both 'gender' and 'lastName' controls
 * @returns Validation errors if gender is missing when lastName is set, null otherwise
 *
 * @example
 * ```typescript
 * const form = new FormGroup({
 *   gender: new FormControl(''),
 *   lastName: new FormControl(''),
 * }, {
 *   validators: [requireGenderWhenNameIsSet]
 * });
 * ```
 */
export function requireGenderWhenNameIsSet(control: UntypedFormGroup): ValidationErrors | null {
  const genderControl = control.get('gender');
  const nameControl = control.get('lastName');

  if (nameControl && !Validators.required(nameControl)) {
    const errors = Validators.min(1)(genderControl) ? { required: true } : null;

    if (errors) {
      genderControl.setErrors(errors);
    }
    return errors;
  }
  return null;
}
